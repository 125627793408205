<template>
  <b-card no-body>
    <b-card-body
      class="filter-panel"
    >
      <div
        v-if="dateRangeDropdownFilter"
        class="filter"
      >
        <DatePeriod
          v-model="timeFilter"
          :value="dateRangeDropdownValue"
          :period-options="dateRangeDropdownOptions"
          @update="onFilterChange"
        />
      </div>
      <div
        v-if="dateRangeFilter"
        class="filter"
      >
        <date-range
          v-model="dateRange"
          :value="dateRange"
          @update="onFilterChange"
        />
      </div>
      <div
        v-if="marketplaceFilter"
        class="filter"
      >
        <v-select
          v-model="marketplaces"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="title"
          placeholder="All Marketplaces"
          :close-on-select="false"
          :deselect-from-dropdown="true"
          :options="marketplaceOptions"
          @input="onFilterChange"
        />
      </div>
      <div
        v-if="productFilter"
        class="filter"
      >
        <b-form-input
          id="second_input"
          placeholder="Select Products"
        />
      </div>
      <div
        v-if="searchFilter"
        class="filter"
      >
        <b-form-input
          id="filter-search"
          v-model="search"
          :placeholder="searchFilterPlaceholder"
          debounce="500"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import DateRange from '@/components/formElements/DateRange.vue'
import DatePeriod from '@/components/formElements/DatePeriod.vue'

export default {
  name: 'FilterPanel',
  components: {
    DateRange,
    BCard,
    BCardBody,
    BFormInput,
    vSelect,
    DatePeriod,
  },
  props: {
    marketplaceValue: {
      type: Array,
      default: () => ([]),
    },
    productValue: {
      type: Array,
      default: () => ([]),
    },
    searchValue: {
      type: String,
      default: null,
    },
    dateRangeValue: {
      type: Object,
      default: () => ({
        startDate: moment().hour(0).minute(0).second(0)
          .toDate(),
        endDate: moment().hour(23).minute(59).second(59)
          .toDate(),
      }),
    },
    dateRangeDropdownValue: {
      type: String,
      default: 'last12months',
    },
    dateRangeDropdownOptions: {
      type: Array,
      default: () => ([]),
    },
    marketplaceFilter: {
      type: Boolean,
      default: false,
    },
    dateRangeFilter: {
      type: Boolean,
      default: false,
    },
    productFilter: {
      type: Boolean,
      default: false,
    },
    dateRangeDropdownFilter: {
      type: Boolean,
      default: false,
    },
    searchFilter: {
      type: Boolean,
      default: false,
    },
    searchFilterPlaceholder: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      dateRange: {},
      marketplaces: [],
      search: undefined,
      timeFilter: undefined,
      products: [],
    }
  },
  computed: {
    marketplaceOptions() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
  },
  watch: {
    search() {
      this.onFilterChange()
    },
  },
  created() {
    this.dateRange = this.dateRangeValue
    this.marketplaces = this.marketplaceValue
    this.products = this.productValue
    this.search = this.searchValue
    this.timeFilter = this.dateRangeDropdownValue
  },
  methods: {
    onFilterChange() {
      this.$emit('update', {
        marketplaces: this.marketplaces.map(marketplace => marketplace.id),
        dateRange: this.dateRange,
        search: this.search,
        products: this.products,
        timeFilter: this.timeFilter,
      })
    },
  },
}
</script>

<style scoped>

</style>
