<template>
  <div
    class="average-sales-table d-flex flex-column p-1"
  >
    <div class="header d-flex flex-row justify-content-between">
      <h5 class="title">
        {{ title }}
      </h5>
    </div>
    <div
      ref="scrollable"
      class="d-flex flex-1 flex-column"
      :class="{ 'collapsed': collapsed }"
      @scroll="onScroll"
    >
      <b-table
        :responsive="!collapsed"
        :items="dataSource"
        :fields="fields"
        class="mb-0 text-right"
        @row-hovered="onRowHover"
      >
        <template #head()="data">
          <span
            :data-column-name="data.field.key"
          >{{ data.label }}</span>
        </template>
        <template #cell(percent)="data">
          <span
            v-b-tooltip.hover="data.value.amount ? data.value.amount.toFixed(2) : ''"
            class="text-nowrap"
          >
            {{ data.value.display }}
          </span>
        </template>
        <template #cell(orders)="data">
          <span
            class="text-nowrap"
          >
            {{ data.value.toFixed(2) }}
          </span>
        </template>
        <template #cell(cumulative)="data">
          <span
            v-b-tooltip.hover="data.value.amount ? data.value.amount.toFixed(2) : ''"
            class="text-nowrap"
          >
            {{ data.value.display }}
          </span>
        </template>
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'AverageSalesTable',
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    summary: {
      type: Array,
      default: () => ([]),
    },
    hoveredHour: {
      type: Number,
      default: -1,
    },
    hourFilter: {
      type: Array,
      default: () => ([]),
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showSummary() {
      return this.summary.length
    },
    dataSource() {
      if (this.summary) {
        return this.items.filter(item => this.hourFilter.includes(item.hour)).map(item => ({ ...item, _rowVariant: item.hour === this.hoveredHour ? 'hovered' : '' })).concat(this.summary)
      }
      return this.items.filter(item => this.hourFilter.includes(item.hour)).map(item => ({ ...item, _rowVariant: item.hour === this.hoveredHour ? 'hovered' : '' }))
    },
  },
  created() {
    EventBus.$on('forecast-scroll', this.onForecastScroll)
  },
  methods: {
    onScroll(e) {
      EventBus.$emit('forecast-scroll', {
        top: e.srcElement.scrollTop,
        left: e.srcElement.scrollLeft,
      })
    },
    onRowHover({ hour }) {
      EventBus.$emit('forecast-hovered-hour', hour)
    },
    onForecastScroll(value) {
      if (this.collapsed) {
        this.$refs.scrollable.scrollTop = value.top
        this.$refs.scrollable.scrollLeft = value.left
      }
    },
  },
}
</script>

<style lang="scss">
  .average-sales-table {
    tr.table-hovered {
      background-color: #7366f0;

      td {
        color: #fff !important;
      }
    }

    .collapsed {
      min-width: 100px;
      max-width: 100px;
      overflow-y: hidden;

      th[role="columnheader"] {
        min-width: 120px;
        max-width: 100px;
      }

      @media (max-width: 390px) {
        min-width: inherit;
        max-width: inherit;
        overflow-y: inherit;
      }
    }
  }
</style>
