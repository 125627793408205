<template>
  <section id="sales-forecasts">
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <filter-panel
          :date-range-dropdown-value="filters.timeFilter"
          :search-value="filters.search"
          search-filter-placeholder="Search by ASIN"
          :date-range-dropdown-options="dateRangeDropdownOptions"
          date-range-dropdown-filter
          search-filter
          @update="onFilterUpdate"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <b-card no-body>
          <b-card-body
            class="p-0"
            :class="{'full-screen': fullScreen}"
          >
            <div class="d-flex flex-column">
              <div class="p-1">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="onToggleView"
                >
                  Compare View Toggle
                </b-button>
                <b-form-checkbox-group
                  v-model="selectedDays"
                  :options="days"
                  name="flavour-1a"
                  class="demo-inline-spacing"
                />
                <b-form-checkbox-group
                  v-model="selectedHours"
                  :options="hours"
                  name="flavour-1a"
                  class="demo-inline-spacing"
                />
              </div>
              <div class="d-flex flex-row flex-wrap forecasts">
                <average-sales-table
                  title="Month Average"
                  :sticky-header="true"
                  :no-border-collapse="true"
                  :fields="fields"
                  :items="monthAverage"
                  :hovered-hour="hoveredHour"
                  :hour-filter="selectedHours"
                  :summary="[{ orders: monthAverageTotalOrders }]"
                />
                <average-sales-table
                  v-for="item of daysAverageFiltered"
                  :key="item.day"
                  class="day-forecast-table"
                  :sticky-header="true"
                  :no-border-collapse="true"
                  :title="item.day"
                  :fields="fields"
                  :items="item.hours"
                  :hovered-hour="hoveredHour"
                  :hour-filter="selectedHours"
                  :collapsed="fullScreen"
                  :summary="[{ orders: item.totalOrders }]"
                />
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard, BCardBody, BCol, BRow, BButton, BFormCheckboxGroup,
} from 'bootstrap-vue'
import FilterPanel from '@/components/FilterPanel.vue'
import AverageSalesTable from '@/views/pages/sales/forecasts/components/AverageSalesTable.vue'
import EventBus from '@/utils/event-bus'

const initialSelected = [...Array(24).keys()].map(hour => hour)
export default {
  name: 'SalesForecasts',
  components: {
    AverageSalesTable,
    FilterPanel,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormCheckboxGroup,
  },
  data() {
    return {
      dateRangeDropdownOptions: [
        {
          id: 'lastMonth',
          text: 'Last Month',
          singleDay: false,
        },
        {
          id: 'last3months',
          text: 'Last 3 Months',
          singleDay: false,
        },
        {
          id: 'last6months',
          text: 'Last 6 Months',
          singleDay: false,
        },
        {
          id: 'last9months',
          text: 'Last 9 Months',
          singleDay: false,
        },
        {
          id: 'last12months',
          text: 'Last 12 Months',
          singleDay: false,
        },
      ],
      days: [
        { text: 'Monday', value: 'monday' },
        { text: 'Tuesday', value: 'tuesday' },
        { text: 'Wednesday', value: 'wednesday' },
        { text: 'Thursday', value: 'thursday' },
        { text: 'Friday', value: 'friday' },
        { text: 'Saturdays', value: 'saturday' },
        { text: 'Sunday', value: 'sunday' },
      ],
      selectedDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      selectedHours: initialSelected,
      hours: [...Array(24).keys()].map(hour => ({ text: `${hour}:00`, value: hour })),
      fields: [
        'hour',
        {
          key: 'percent', label: ' % Avg.',
        },
        'orders',
        {
          key: 'cumulative', label: '% Cum.',
        },
      ],
      filters: {
        timeFilter: 'lastMonth',
        search: undefined,
      },
      daysAverage: [],
      monthAverage: [],
      monthAverageTotalOrders: 0,
      fullScreen: false,
      hoveredHour: undefined,
    }
  },
  computed: {
    daysAverageFiltered() {
      return this.daysAverage.filter(item => this.selectedDays.includes(item.day.toLowerCase()))
    },
  },
  watch: {
    filters: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  created() {
    this.loadData()
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.fullScreen = false
      }
    })
    EventBus.$on('forecast-hovered-hour', hour => {
      this.hoveredHour = hour
    })
  },
  destroyed() {
    EventBus.$off('forecast-hovered-hour')
  },
  methods: {
    loadData() {
      this.$store.dispatch('sales/fetchForecastData', {
        time_filter: this.filters.timeFilter,
        asin: this.filters.search,
      }).then(response => {
        this.monthAverage = response?.monthAverage?.hours ?? []
        this.daysAverage = response?.daysAverage ?? []
        this.monthAverageTotalOrders = response?.monthAverage?.totalOrders ?? 0
      })
    },
    onFilterUpdate(data) {
      this.filters.timeFilter = data.timeFilter
      this.filters.search = data.search
    },
    onToggleView() {
      this.fullScreen = !this.fullScreen
    },
  },
}
</script>

<style lang="scss" scoped>
  .full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1050;
    background-color: #283046;
    overflow: auto;

    @media (max-width: 390px) {
      .forecasts {
        .average-sales-table {
          height: 20vh;
          overflow: auto;
        }
      }
    }
  }
</style>
